/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif; 
    background-color: #F9F9F9;
}

ngb-carousel {
    max-width: 700px;
    margin: 50px auto;
}

ngb-carousel img {
    width: 100%;
    outline: none;
}

ngb-carousel {
    width: inherit;
    height: inherit;
}

.carousel-inner {
    overflow: visible;
}

.carousel-item {
    display: flex !important;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1.2s ease-in-out, visibility 1.2s;
    z-index: -1;
}

.carousel-item.active{
    opacity: 1;
    visibility: visible;
    z-index: 10;
}

.carousel-control-prev {
     z-index: 20;
}


.carousel-control-next {
     z-index: 20;
}

.carousel-indicators{
    z-index: 20;
}


